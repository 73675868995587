import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CountryObj, Currencies, ProjectTags } from 'src/interfaces/InterfaceGlobal'
import type { AppState } from '../../app/store'

export interface CompensationState {
  currency_token: string;
  currency_code: string;
  currencySymbol: string;
  currencies: Array<Currencies>;
  categories: Array<string>;
  categorySelected: string;
  standardTypes: Array<string>;
  energyTypes: Array<string>;
  compensationTypes: Array<string>;
  projectTags: Array<ProjectTags>;
  maxPrice: number;
  maxStock: number;
  impact: Array<string>;
  mechanism: Array<string>;
  differentCountries: Array<CountryObj>;
  differentCategories: Array<any>;
  registryTypes: Array<string>;
}

const initialState: CompensationState = {
  currency_token: '',
  currency_code: '',
  currencySymbol: '',
  currencies: [],
  categories: [],
  categorySelected: '',
  standardTypes: [],
  energyTypes: [],
  compensationTypes: [],
  projectTags: [],
  maxPrice: 100,
  maxStock: 0,
  impact: [],
  mechanism: [],
  differentCountries: [],
  differentCategories: [],
  registryTypes: []
}

export const compensationSlice = createSlice({
  name: 'compensation',
  initialState,
  reducers: {
    setCurrency: (state: any, action: PayloadAction<string>) => {
      state.currency_token = action.payload
    },
    setCurrencyCode: (state: any, action: PayloadAction<string>) => {
      state.currency_code = action.payload
    },
    setCurrencySymbol: (state: any, action: PayloadAction<string>) => {
      state.currencySymbol = action.payload
    },
    setCurrencies: (state: any, action: PayloadAction<Array<Currencies>>) => {
      state.currencies = action.payload
    },
    setCategories: (state: any, action: PayloadAction<Array<string>>) => {
      state.categories = action.payload
    },
    setStandardTypes: (state: any, action: PayloadAction<Array<string>>) => {
      state.standardTypes = action.payload
    },
    setEnergyTypes: (state: any, action: PayloadAction<Array<string>>) => {
      state.energyTypes = action.payload
    },
    setCompensationTypes: (state: any, action: PayloadAction<Array<string>>) => {
      state.compensationTypes = action.payload
    },
    setRegistryTypes: (state: any, action: PayloadAction<Array<string>>) => {
      state.registryTypes = action.payload
    },
    setCategorySelected: (state: any, action: PayloadAction<string>) => {
      state.categorySelected = action.payload
    },
    setProjectTags: (state: any, action: PayloadAction<Array<ProjectTags>>) => {
      state.projectTags = action.payload
    },
    setMaxPrice: (state: any, action: PayloadAction<number>) => {
      state.maxPrice = action.payload
    },
    setMaxStock: (state: any, action: PayloadAction<number>) => {
      state.maxStock = action.payload
    },
    setDifferentCountries: (state: any, action: PayloadAction<Array<CountryObj>>) => {
      state.differentCountries = action.payload
    },
    setDifferentCategories: (state: any, action: PayloadAction<Array<any>>) => {
      state.differentCategories = action.payload
    },
    setImpacts: (
      state: any,
      action: PayloadAction<Array<string>>
    ) => {
      state.impact = action.payload
    },
    setMechanism: (
      state: any,
      action: PayloadAction<Array<string>>
    ) => {
      state.mechanism = action.payload
    },
  },
})
export const {
  setCurrency,
  setCurrencyCode,
  setCurrencySymbol,
  setCurrencies,
  setCategories,
  setCategorySelected,
  setStandardTypes,
  setEnergyTypes,
  setCompensationTypes,
  setProjectTags,
  setMaxPrice,
  setMaxStock,
  setImpacts,
  setMechanism,
  setDifferentCountries,
  setDifferentCategories,
  setRegistryTypes
} = compensationSlice.actions

export const selectCurrencyToken = (state: AppState) =>
  state.compensation.currency_token
export const selectCurrencies = (state: AppState) => {
  state.compensation.currencies
}

export default compensationSlice.reducer

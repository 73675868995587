import searchSuggesterService from './api'
import { AppDispatch, AppState } from 'src/app/store'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SuggesterFilterItem, SuggesterSliceState } from 'src/interfaces'

export const suggesterSlice = createSlice({
  name: 'searchSuggester',
  initialState: {
    loading: false,
    data: [],
  } as SuggesterSliceState,
  reducers: {
    setSuggesterFilterLoading: (state: SuggesterSliceState, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setSuggesterFilterData: (state: SuggesterSliceState, action: PayloadAction<SuggesterFilterItem[]>) => {
      state.data = action.payload
    },
    resetSuggesterState: (state: SuggesterSliceState) => {
      state.loading = false
      state.data = []
    }
  },
})

export const {
  setSuggesterFilterLoading,
  setSuggesterFilterData,
  resetSuggesterState
} = suggesterSlice.actions

export const suggesterStateSelector = (state: AppState) => state.suggester

export const filterByTextSuggester = (text: string, language: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setSuggesterFilterLoading(true))
    const results = await searchSuggesterService.filterByText(text, language)
    dispatch(setSuggesterFilterData(results))
    dispatch(setSuggesterFilterLoading(false))
  } catch (error) {
    dispatch(resetSuggesterState())
  }
}

export default suggesterSlice.reducer

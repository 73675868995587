import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'

import compensationReducer from '../features/compensation/compensationSlice'
import userReducer from '../features/users/userSlice'
import uiReducer from '../features/ui/uiSlice'
import projectReducer from 'src/features/projects/projectSlice'
import filtersReducer from 'src/features/filters/filtersSlice'
import suggesterSlice from 'src/features/search-suggester/slice'

export function makeStore() {
  return configureStore({
    reducer: {
      compensation: compensationReducer,
      project: projectReducer,
      ui: uiReducer,
      user: userReducer,
      filters: filtersReducer,
      suggester: suggesterSlice,
    },
  })
}

const store = makeStore()

export type AppState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action<string>
>;

export default store

/* istanbul ignore file */
import App from 'next/app'
import 'antd/dist/antd.css'
import '/src/assets/styles/globals.scss'
import '/src/config/axios'
import type { AppProps } from 'next/app'
import { appWithTranslation } from 'next-i18next'
import Layout from '../components/Layout/Layout'
import React from 'react'
import { Provider } from 'react-redux'
import store from '../app/store'
import { getHeaderData } from 'src/features/header/headerAPI'

interface MyAppProps extends AppProps {
  headerData?: any
}



const MyApp = ({ Component, pageProps, headerData }: MyAppProps) => {
  const AnyComponent = Component as any
  const ProviderRedux = Provider as any

  return (
    <ProviderRedux store={store}>
      <Layout headerData={headerData} >
        <AnyComponent {...pageProps} />
      </Layout>
    </ProviderRedux>
  )
}


MyApp.getInitialProps = async (ctx) => {
  const appProps = await App.getInitialProps(ctx)
  
  let headerData = await getHeaderData(ctx.router.locale)
  return { ...appProps, headerData }
}

export default appWithTranslation(MyApp)

import React from 'react'
import { Modal, notification } from 'antd'
import { WelcomeBackMessage } from '@climatetrade/components'
import { useTranslation } from 'next-i18next'
import Cookie from 'js-cookie'
import { setUpdateUser } from 'src/features/users/userSlice'
import { useAppSelector } from 'src/app/hooks'
import { useDispatch } from 'react-redux'
import { loginUserAPI } from 'src/features/users/usersAPI'
import { NotificationPlacement } from 'antd/lib/notification'

interface WelcomeBackContentProps {
  isModalVisible: boolean;
  handleCancel: () => void
}

const WelcomeBackModal: React.FC<WelcomeBackContentProps> = ({ isModalVisible, handleCancel }) => {
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()
  const [email, setEmail] = React.useState<string>()
  const [password, setPassword] = React.useState<string>()
  const updateUser = useAppSelector((state) => state.user.updateUser)

  const openNotification = (placement: NotificationPlacement) => {
    const errorMessage = {
      message: t('notification.errorLogIn'),
      placement
    }

    notification.error(errorMessage)
  }

  const setLoginCookie = () => {
    const expirationDate = new Date()
    expirationDate.setMonth(expirationDate.getMonth() + 3)
    Cookie.set('ct_loggedIn', 'true', {
      domain: process.env.CT_DOMAIN,
      path: '/',
      expires: expirationDate,
    })
  }
  
  const handlerOnLoginClick = () => {
    const ct_vid_cookie = Cookie.get('ct_vid')
    const payload = { email, password, language: i18n.language, visitor_id: ct_vid_cookie }
    loginUserAPI(payload)
      .then(res => {
        setLoginCookie()
        Cookie.set(process.env.TOKEN_NAME, res.token, {
          domain: process.env.CT_DOMAIN,
          path: '/',
          expires: new Date(Date.now() + 3600000),
        })
        dispatch(setUpdateUser(!updateUser))
        handleCancel()
      })
      .catch(e => { 
        openNotification('top')
        console.error(e)
      })
  }


  return (
    <Modal width={400} visible={isModalVisible} onCancel={handleCancel} footer={null} centered={true}>
      <WelcomeBackMessage onLoginClick={handlerOnLoginClick} setPassword={setPassword} setEmail={setEmail}/>
    </Modal>
  )
}

export default WelcomeBackModal

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = {
  loading: false,
  trackHome: false,
  trackResult: false,
  trackHowItWorks: false,
  filtersKeys: ['projectKind'],
  loadingSliderStock: false,
  loadingSliderPrice: false,
}

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setFiltersKeys: (state, action: PayloadAction<Array<string>>) => {
      state.filtersKeys = action.payload
    },
    setTrackHome: (state, action: PayloadAction<boolean>) => {
      state.trackHome = action.payload
    },
    setTrackResult: (state, action: PayloadAction<boolean>) => {
      state.trackResult = action.payload
    },
    setTrackHowItWorks: (state, action: PayloadAction<boolean>) => {
      state.trackHowItWorks = action.payload
    },
    setLoadingSliderPrice: (state, action: PayloadAction<boolean>) => {
      state.loadingSliderPrice = action.payload
    },
    setLoadingSliderStock: (state, action: PayloadAction<boolean>) => {
      state.loadingSliderStock = action.payload
    },
  },
})

export const {
  setLoading,
  setFiltersKeys,
  setTrackHome,
  setTrackResult,
  setTrackHowItWorks,
  setLoadingSliderStock,
  setLoadingSliderPrice,
} = uiSlice.actions

export default uiSlice.reducer

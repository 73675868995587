import axios from 'axios'
import { i18n } from 'next-i18next'
import Cookie from 'js-cookie'
import { getCurrencyCookie } from 'src/helpers/cookies.helpers'

const baseURL = process.env.API_URL
const tokenName = process.env.TOKEN_NAME
const currencyCodeEnv = process.env.CURRENCY_CODE

axios.defaults.baseURL = baseURL

axios.interceptors.request.use(
  async function (config) {
    const currencyCodeStorage = getCurrencyCookie()
    let userToken = Cookie.get(tokenName)

    if (userToken) {
      config.headers['Accept-Language'] = i18n.language
      config.headers['Authorization'] = `JWT ${userToken}`
    } else {
      config.headers['Accept-Language'] = i18n.language
      if (currencyCodeStorage) {
        config.headers['X-Currency-Code'] = currencyCodeStorage
      } else {
        config.headers['X-Currency-Code'] = currencyCodeEnv
      }
    }
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    const code = error.response.status
    if (code === 403 || code === 401) {
      window.location.href = `${window.location.origin}/accounts/auth/login`
    }
    return Promise.reject(error)
  }
)

export default axios

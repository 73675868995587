import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Portfolio } from 'src/interfaces/InterfaceGlobal'

const initialState = {
  project: null,
  portfolios: [],
  year: '',
  indexYear: 0,
  cart: null,
  reloadProject: false,
  cartLength: 0
}

export const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    setProject: (state, action: PayloadAction<any>) => {
      state.project = action.payload
    },
    setPortfolios: (state, action: PayloadAction<Portfolio[]>) => {
      state.portfolios = action.payload
    },
    setYear: (state, action: PayloadAction<any>) => {
      state.year = action.payload
    },
    setIndexYear: (state, action: PayloadAction<any>) => {
      state.indexYear = action.payload
    },
    setCart: (state, action: PayloadAction<object>) => {
      state.cart = action.payload
    },
    setReloadProjects: (state, action: PayloadAction<boolean>) => {
      state.reloadProject = action.payload
    },
    setCartLength: (state, action: PayloadAction<number>) => {
      state.cartLength = action.payload
    }
  },
})

export const {
  setProject,
  setPortfolios,
  setYear,
  setIndexYear,
  setCart,
  setReloadProjects,
  setCartLength
} = projectSlice.actions

export default projectSlice.reducer

import axios from 'axios'


const strapiAPI = axios.create({ baseURL: process.env.STRAPI_API_URL })

interface CartType {
    id: string;
    label?: string;
    link: string;
    showArrow: boolean;
    icon: string;
}
interface Header {
    content: any;
    toolboxProps: any;
    menuProps: any;
    cartProps: CartType;
    userProps: any;
    locationProps: any;
    onLanguageChange?: () => void;
}

export type AvailableLocale = 'en' | 'es' | 'fr' | 'ko' | 'de' | 'it' | 'pt' | 'pl' | 'zh'

export const _fetchHeaderData = async (lang: AvailableLocale = 'en'): Promise<Header> => {
  try {
    const { data }  = await strapiAPI.get(
      `${process.env.STRAPI_API_URL}/header?populate=deep,6&locale=${lang}`
    )
    return data?.data
  } catch(error) {
    console.error(`[_fetchHeaderData]`, error)
  }

}

export const getHeaderData = async (lang: AvailableLocale = 'en'): Promise<Header> => {
  return await _fetchHeaderData(lang) || await _fetchHeaderData()
}
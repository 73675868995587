import { createSlice, PayloadAction } from '@reduxjs/toolkit'
// import type { AppState } from '../../app/store';
// Interfaces
import { CountryObj, Ordering, SaveFilters } from 'src/interfaces/InterfaceGlobal'

export interface FiltersState {
  name: string;
  filteredProjects: Array<any> | null;
  projectKind: Array<string>;
  countries: Array<CountryObj>;
  yearSince: number | null;
  yearUntil: number | null;
  price: Array<number> | null;
  stock: Array<number> | null;
  selectedStandardType: Array<string>;
  selectedEnergyType: Array<string>;
  selectedCompensationType: Array<string>;
  selectedProjectTags: Array<number>;
  selectedTypes: Array<any>;
  SDGs: Array<number>;
  withoutStock: boolean;
  refreshFilters: boolean;
  yearChange: boolean;
  priceChange: boolean;
  stockChange: boolean;
  order: Ordering;
  search: string | null;
  selectedImpacts: Array<string>;
  selectedMechanism: Array<string>;
  //se han cargado filtros guardados?
  sessionLoaded: boolean;
  queryWrite: boolean;
  queryRead: boolean;
  isReset: boolean;
  registry: Array<string>;
  minimumPurchase: boolean;
}

const initialState: FiltersState = {
  name: '',
  filteredProjects: null,
  projectKind: [],
  countries: [],
  yearSince: null,
  yearUntil: null,
  price: [0.01, null],
  stock: [0, null],
  selectedStandardType: [],
  selectedEnergyType: [],
  selectedCompensationType: [],
  selectedProjectTags: [],
  selectedTypes: [],
  SDGs: [],
  withoutStock: true,
  refreshFilters: false,
  order: '-scoring',
  yearChange: false,
  priceChange: false,
  stockChange: false,
  search: null,
  selectedImpacts: [],
  sessionLoaded: false,
  queryWrite: false,
  queryRead: true,
  isReset: false,
  selectedMechanism: [],
  registry: [],
  minimumPurchase: true
}

export const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setFilteredProjects: (state: FiltersState, action: PayloadAction<Array<any>>) => {
      state.filteredProjects = action.payload
    },
    setProjectKind: (
      state: any,
      action: PayloadAction<string | Array<string>>
    ) => {
      state.projectKind = action.payload
    },
    setCountries: (state: FiltersState, action: PayloadAction<Array<CountryObj>>) => {
      state.countries = action.payload
    },
    setYearSince: (state: FiltersState, action: PayloadAction<number>) => {
      state.yearSince = action.payload
    },
    setYearUntil: (state: FiltersState, action: PayloadAction<number>) => {
      state.yearUntil = action.payload
    },
    setPrice: (state: FiltersState, action: PayloadAction<Array<number>>) => {
      state.price = action.payload
    },
    setStock: (state: FiltersState, action: PayloadAction<Array<number>>) => {
      state.stock = action.payload
    },
    setSelectedStandardType: (
      state: FiltersState,
      action: PayloadAction<Array<string>>
    ) => {
      state.selectedStandardType = action.payload
    },
    setSelectedEnergyType: (
      state: FiltersState,
      action: PayloadAction<Array<string>>
    ) => {
      state.selectedEnergyType = action.payload
    },
    setSelectedCompensationType: (
      state: FiltersState,
      action: PayloadAction<Array<string>>
    ) => {
      state.selectedCompensationType = action.payload
    },
    setSelectedProjectTags: (
      state: FiltersState,
      action: PayloadAction<Array<number>>
    ) => {
      state.selectedProjectTags = action.payload
    },
    setSelectedTypes: (state: FiltersState, action: PayloadAction<Array<any>>) => {
      state.selectedTypes = action.payload
    },
    setSDGs: (state: FiltersState, action: PayloadAction<Array<number>>) => {
      state.SDGs = action.payload
    },
    setWithoutStock: (state: FiltersState, action: PayloadAction<boolean>) => {
      state.withoutStock = action.payload
    },
    setRefreshFilters: (state: FiltersState, action: PayloadAction<boolean>) => {
      state.refreshFilters = action.payload
    },
    setName: (state: FiltersState, action: PayloadAction<string>) => {
      state.name = action.payload
    },
    setRegistrySelected: (state: FiltersState, action: PayloadAction<Array<string>>) => {
      state.registry = action.payload
    },
    setSearch: (state: FiltersState, action: PayloadAction<string>) => {
      state.search = action.payload
    },
    setOrder: (state: FiltersState, action: PayloadAction<Ordering>) => {
      state.order = action.payload
    },
    setYearChange: (state: FiltersState, action: PayloadAction<boolean>) => {
      state.yearChange = action.payload
    },
    setPriceChange: (state: FiltersState, action: PayloadAction<boolean>) => {
      state.priceChange = action.payload
    },
    setStockChange: (state: FiltersState, action: PayloadAction<boolean>) => {
      state.stockChange = action.payload
    },
    setIsReset: (state: FiltersState, action: PayloadAction<boolean>) => {
      state.isReset = action.payload
    },
    setSelectedImpacts: (state: FiltersState, action: PayloadAction<Array<string>>) => {
      state.selectedImpacts = action.payload
    },
    setSelectedMechanism: (state: FiltersState, action: PayloadAction<Array<string>>) => {
      state.selectedMechanism = action.payload
    },
    setQueryWrite: (state: FiltersState, action: PayloadAction<boolean>) => {
      state.queryWrite = action.payload
    },
    setQueryRead: (state: FiltersState, action: PayloadAction<boolean>) => {
      state.queryRead = action.payload
    },
    setSessionLoaded: (state: FiltersState, action: PayloadAction<boolean>) => {
      state.sessionLoaded = action.payload
    },
    setMinimumPurchase: (state: FiltersState, action: PayloadAction<boolean>) => {
      state.minimumPurchase = action.payload
    },
    setQueryFilters: (state: FiltersState, action: PayloadAction<any>) => {
      state.search=action.payload.search
      state.countries=action.payload.countries
      state.selectedProjectTags=action.payload.selectedProjectTags
      state.selectedTypes=action.payload.selectedTypes
      state.SDGs=action.payload.SDGs
      state.projectKind=action.payload.projectKind
      state.selectedMechanism=action.payload.selectedMechanism
      state.queryRead=!state.queryRead
    },
    setFilters: (state: FiltersState, action: PayloadAction<SaveFilters>) => {
      state.name = action.payload.name
      state.projectKind = action.payload.projectKind
      state.yearSince = action.payload.yearSince
      state.yearUntil = action.payload.yearUntil
      state.price = action.payload.price
      state.selectedStandardType = action.payload.selectedStandardType
      state.selectedEnergyType = action.payload.selectedEnergyType
      state.selectedCompensationType = action.payload.selectedCompensationType
      state.selectedMechanism = action.payload.selectedMechanism
      state.withoutStock = action.payload.withoutStock
      state.order = action.payload.order
      state.stock = action.payload.stock
      state.selectedImpacts = action.payload.selectedImpacts
      state.sessionLoaded = true
      state.yearChange = action.payload.yearChange
      state.stockChange = action.payload.stockChange
      state.priceChange = action.payload.priceChange
      state.registry = action.payload.registry
    },

    setInitialState: () => initialState,
  },
})
export const {
  setQueryFilters,
  setFilteredProjects,
  setProjectKind,
  setCountries,
  setYearSince,
  setYearUntil,
  setPrice,
  setSelectedStandardType,
  setSDGs,
  setName,
  setSelectedEnergyType,
  setSelectedCompensationType,
  setSelectedProjectTags,
  setSelectedTypes,
  setWithoutStock,
  setRefreshFilters,
  setInitialState,
  setOrder,
  setYearChange,
  setPriceChange,
  setFilters,
  setStock,
  setStockChange,
  setSearch,
  setSelectedImpacts,
  setIsReset,
  setSelectedMechanism,
  setQueryWrite,
  setQueryRead,
  setSessionLoaded,
  setRegistrySelected,
  setMinimumPurchase
} = filtersSlice.actions

export default filtersSlice.reducer

import axios from 'axios'
import { Currencies, ProjectTags } from 'src/interfaces/InterfaceGlobal'

export async function currencyConverter(): Promise<Array<Currencies>> {
  const response = await axios.get('/api/v1/currency_tokens/')
  return response.data.results
}

export async function getProjectsTags(): Promise<Array<ProjectTags>> {
  const response = await axios.get('/api/v1/projecttags/')
  return response.data
}

export async function getSocialProof(): Promise<Array<string>> {
  const response = await axios.get('/api/v1/social_proof_pills/')
  return response.data
}

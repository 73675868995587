import { Footer } from '@climatetrade/components'
import { i18n } from 'next-i18next'
import packageJson from '../../../package.json'
import React from 'react'


const FooterContainer: React.FC = () => {
  
  return (
    <Footer language={i18n?.language}>
      <span style={{ marginTop: '10px' }}>V{packageJson.version}</span>
    </Footer>
  )
}

export default FooterContainer

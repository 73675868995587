import axios,{ AxiosInstance } from 'axios'
import { SuggesterFilterItem, SuggesterFilterResponse } from 'src/interfaces'

class SearchSuggesterService {
  private axiosInstance: AxiosInstance

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: `${process.env.SEARCH_SUGGESTER_API_URL}/api/v1`
    })
  }

  async filterByText(text: string, language: string): Promise<SuggesterFilterItem[]> {
    const url = '/filters/get-data'
    const { data } = await this.axiosInstance.post<SuggesterFilterResponse>(url, { text, language })
    return data.data
  }
}

const searchSuggesterService = new SearchSuggesterService()
export default searchSuggesterService

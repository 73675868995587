import axios from 'axios'
import Cookie from 'js-cookie'
import { ct_sid_name, ct_vid_name } from 'src/static/models/cookies.names'

export async function postVisitorAPI(payload: any): Promise<any> {
  const { data } = await axios.post('/api/v1/visitors/', payload)
  return data
}

export async function postTrackingEventAPI(
  url: string,
  event: string,
  eventValue?: string,
  eventData?: string
): Promise<any> {
  const ct_sid_cookie = Cookie.get(ct_sid_name)
  const ct_vid_cookie = Cookie.get(ct_vid_name)
  const values = {
    event,
    url,
    ct_sid: ct_sid_cookie,
    ct_vid: ct_vid_cookie,
    eventValue: eventValue ? eventValue : null,
    eventData: eventData ? eventData : null,
    referral: null,
    integration: null,
  }
  const { data } = await axios.post(
    '/api/v1/visitors/add_track_event/',
    values
  )
  return data
}

import axios from 'axios'
import { Country, LoginFormPayload, RequestForProposalPayload, User } from 'src/interfaces/InterfaceGlobal'

export async function sendRequestForProposal(payload: RequestForProposalPayload) {
  await axios.post('/api/v1/requests_for_propsals/', {
    ...payload,
    countries: payload.countries || [],
    standards: payload.standards || [],
    types: payload.types || [],
  })
}

export async function postNewsSuscription(payload: any){
  await axios.post('/api/v1/forms-gateway/salesforce/', {
    ...payload
  })
}

export async function validToken(token: string) {
  const response = await axios.post('/api/token-verify/', { token })
  return response.data
}

export async function fetchUser(): Promise<User> {
  const response = await axios.get('/api/v1/users/me/')
  return response.data
}

export async function refreshToken(userToken: string) {
  const response = await axios.post('/api/token-refresh/',
    {
      token: userToken,
    },
    {
      headers: { Authorization: `JWT ${userToken}` },
    }
  )
  return response.data
}

export async function changeUserLanguageRequest(
  payload
): Promise<{ data: object[] }> {
  const response = await axios.patch('/api/v1/users/set_language/', payload)
  return response.data.user
}

export async function fetchCountries(): Promise<Country[]> {
  const response = await axios.get('/api/v1/countries/', {
    params: { page_size: 9999 },
  })
  return response.data.results
}

export async function fetchValidEmail(email: string) {
  const response = await axios.get('/api/v1/big_register/isemail/', {
    params: { email: email },
  })
  return response.data
}

export async function createUser(body: User) {
  const response = await axios.post('/api/v1/big_register/?source=market', {
    ...body,
  })
  return response.data
}

export async function updateCertificate(formCertificate: any) {
  const response = await axios.put('/api/v1/carts/editOffsetReason/', {
    offset_reason: 2,
    optional: false,
    offset_reason_content: '',
    presentedTo: formCertificate.certificatename,
    certificate_language: formCertificate.certificatelanguage,
  })
  return response.data
}

export async function fetchCompany() {
  const response = await axios.get('/api/v1/companies/')
  return response.data
}

export async function patchUser(payload): Promise<{ data: object[] }> {
  const response = await axios.patch('/api/v1/users/me/', payload)
  return response.data
}

export async function loginUserAPI(payload: LoginFormPayload): Promise<{ token: string }> {
  const { data } = await axios.post<{ token: string }>('/api/token-auth/', payload)
  return data
}

